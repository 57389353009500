import { createStore } from "vuex"
import publicAffiliateLink, { PublicAffiliateLinkState } from "./modules/public-affiliate-link"
import { PublicCompany } from "@/classes/PublicCompany"


export interface StoreState {
  publicAffiliateLink? : PublicAffiliateLinkState
  isLoading: boolean,
  publicCompany: PublicCompany | undefined
}

export default createStore<StoreState>({
    state: {
        isLoading: false,
        publicCompany: undefined
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        publicAffiliateLink
    }
})
