import { initializeApp } from "firebase/app"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getStorage, connectStorageEmulator } from "firebase/storage"

const app = initializeApp({
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID
})

const firestore = getFirestore(app)
const functions = getFunctions(app, "europe-west3")
const storage = getStorage(app)

if (location.hostname == "localhost") {
    connectFirestoreEmulator(firestore, "localhost", 8081)
    connectFunctionsEmulator(functions, "localhost", 5001)
    connectStorageEmulator(storage, "localhost", 9199)
}

export {
    firestore,
    functions,
    storage
}