import { PublicAffiliateLinkOld } from "@/classes/PublicAffiliateLinkOld"
import { onSnapshot, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"

export interface PublicAffiliateLinkState {
  isLoading: boolean
  publicAffiliateLink: PublicAffiliateLinkOld | null
  firebaseListener: {
    publicAffiliateLink: Unsubscribe | null
  }
}

const module: Module<PublicAffiliateLinkState, unknown> = {
    state: {
        isLoading: true,
        publicAffiliateLink: null,
        firebaseListener: {
            publicAffiliateLink: null
        }
    },
    actions: {
        getPublicAffiliateLinkOld({ state }, publicAffiliateLinkID: string) {
            return new Promise(async (resolve, reject) => {
                if (state.firebaseListener.publicAffiliateLink) {
                    state.isLoading = false
                    resolve(state.publicAffiliateLink)
                    return
                }

                state.isLoading = true
                
                const _a = new PublicAffiliateLinkOld(publicAffiliateLinkID)
                

                state.firebaseListener.publicAffiliateLink = onSnapshot(_a.ref, (snapshot) => {
                    if (snapshot.exists()) {
                        const publicAffiliateLink = new PublicAffiliateLinkOld(snapshot.id)
                        publicAffiliateLink.fromData(snapshot.data())
                        state.publicAffiliateLink = publicAffiliateLink
                        state.isLoading = false
                        resolve(state.publicAffiliateLink)
                    }
                    resolve(state.publicAffiliateLink)
                }, (err) => {
                    state.isLoading = false
                    reject(err)
                })
            })
        }
    }
}
export default module
