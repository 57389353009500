import { firestore } from "@/firebase"
import { collection, CollectionReference, doc, DocumentData, DocumentReference } from "firebase/firestore"
import { FirestoreClass } from "./FirestoreClass"

export enum AffiliateLinkOldType {
    UNSET,
    Baufinanzierung = "baufinanzierung",
    Privatkredite = "privatkredite",
    Immobilien = "immobilien"
  }

export class MerchantRef {
    company: string = ""
    user: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.company = data.company as string
            this.user = data.user as string
        }
    }
    toData() {
        return {
            company: this.company,
            user: this.user
        }
    }
}

export class PublicAffiliateLinkOld extends FirestoreClass {
    active: boolean = false
    affiliateLogo: string | null = null
    affiliateName: string | null = null
    merchantRef: MerchantRef = new MerchantRef()
    type: AffiliateLinkOldType = AffiliateLinkOldType.UNSET

    constructor(id: string = "") {
        super(id)
    }

    override fromData(data: Record<string, unknown>): void {
        if (data) {
            this.active = data.active as boolean
            this.affiliateLogo = data.affiliateLogo as string
            this.affiliateName = data.affiliateName as string
            this.merchantRef = data.merchant as MerchantRef
            this.type = data.type as AffiliateLinkOldType
        }
    }

    override get ref(): DocumentReference<DocumentData> {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef(): CollectionReference<DocumentData> {
        return collection(firestore, "public_affiliateLink")
    }
}
