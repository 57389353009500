
import { defineComponent, watch } from "vue"
import { useStore } from "vuex"
import { mdiLoading } from "@mdi/js"
import { PublicCompany } from "./classes/PublicCompany"


export default defineComponent({
    setup() {
        const store = useStore()

        watch(() => store.state.publicCompany, (company: PublicCompany) => {
            if (company) {
                document.title = `CRM - ${company.name}`
                if (company.colors) {
                    document.documentElement.style.setProperty("--color-primary", `${company.colors.primary.r} ${company.colors.primary.g} ${company.colors.primary.b}`)
                    document.documentElement.style.setProperty("--color-primary-hover", `${company.colors.primaryHover.r} ${company.colors.primaryHover.g} ${company.colors.primaryHover.b}`)
                    document.documentElement.style.setProperty("--color-secondary", "0 0 0")
                    document.documentElement.style.setProperty("--color-border", `${company.colors.border.r} ${company.colors.border.g} ${company.colors.border.b}`)
                    document.documentElement.style.setProperty("--color-card", `${company.colors.card.r} ${company.colors.card.g} ${company.colors.card.b}`)
                    document.documentElement.style.setProperty("--color-background", `${company.colors.background.r} ${company.colors.background.g} ${company.colors.background.b}`)
                }
                console.log("Farben gesetzt")
                
            }
        }, { immediate: true })

        return {
            mdiLoading
        }
    },
    components: {
        
    }
})
